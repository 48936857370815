import { useEffect } from "react"

function useEmbedly() {
  return useEffect(() => {
    let embedlyJS, embedlyCSS

    embedlyJS = document.createElement(`script`)
    embedlyJS.src = `/embedly.js`
    document.head.appendChild(embedlyJS)

    embedlyCSS = document.createElement(`link`)
    embedlyCSS.rel = `stylesheet`
    embedlyCSS.className = `embedly-css`
    embedlyCSS.href = `/embedly.css`
    document.head.appendChild(embedlyCSS)

    return () => {
      document.head.removeChild(embedlyJS)
      document.head.removeChild(embedlyCSS)
    }
  }, [])
}

export default useEmbedly

import React from "react"
import { RightOutlined } from "@ant-design/icons"
import {
  Breadcrumb,
  Col,
  Divider,
  PageHeader,
  Row,
  Tag,
  Typography,
} from "antd"
import { graphql, Link } from "gatsby"
import Shell from "../components/Shell/Shell"
import SEO from "@avldev/gatsby-theme-core-ui/src/components/SEO"
import MinimalHero from "../components/MinimalHero/MinimalHero"
import Wistia from "../components/Wistia/Wistia"
import BlogReel from "../components/BlogReel/BlogReel"
import SocialSharing from "../components/SocialSharing/SocialSharing"
import BookingButton from "../components/BookButton/BookButton"
import useEmbedly from "../utils/useEmbedly"
import style from "../styles/blogpost.module.scss"

export default ({ data, location: { href, pathname } }) => {
  const {
    content,
    featuredImage,
    publishedAt,
    tags,
    title,
    updatedAt,
    wistiaVideoUrl,
  } = data.contentfulBlogPost

  const post = content.childMarkdownRemark
  const uri = pathname + `/`

  const rowProps = {
    align: "middle",
    justify: "center",
    type: "flex",
    style: { margin: `auto`, maxWidth: 1360 },
  }

  const contentColProps = {
    xs: 22,
    md: 20,
    lg: 18,
  }

  const heroProps = {
    alt: featuredImage.description,
    color1: null,
    color2: null,
    fluid: true,
    height: `45vh`,
    image: featuredImage.fluid,
  }

  const breadcrumbColProps = {
    xs: 22,
    md: 20,
    lg: 18,
  }

  const breadcrumbProps = {
    className: style.crumbs,
    separator: <RightOutlined />,
  }

  const pageHeaderProps = {
    className: style.pageHeader,
    title: (
      <Typography.Title style={{ fontSize: 40, whiteSpace: `normal` }}>
        {title}
      </Typography.Title>
    ),
  }

  const contentProps = {
    xs: 22,
    md: 20,
    lg: 18,
    className: style.contentWrapper,
    dangerouslySetInnerHTML: {
      __html: post.html,
    },
  }

  const tagColProps = {
    className: style.tagColumn,
    xs: 22,
    md: 20,
    lg: 18,
  }

  const videoRowProps = {
    align: `middle`,
    justify: `center`,
    display: `flex`,
    style: {
      marginBottom: 64,
      marginTop: 36,
      textAlign: `center`,
    },
  }

  const videoColProps = {
    offset: 1,
    span: 22,
    textAlign: `center`,
  }

  const wistiaVideoProps = {
    oEmbedUrl: wistiaVideoUrl,
  }

  if (typeof window !== "undefined") {
    let blogsRead = JSON.parse(localStorage.getItem("blogsRead")) || {}
    blogsRead[uri] = Date.now()
    localStorage.setItem("blogsRead", JSON.stringify(blogsRead))
  }

  useEmbedly()

  return (
    <Shell>
      <SEO
        meta={{
          description: post.excerpt,
          image: featuredImage.fluid.src,
          imageAlt: featuredImage.description,
        }}
        title={`${title} - The Residences at Biltmore`}
        url={href}
      />
      <MinimalHero {...heroProps} />
      <Row {...rowProps}>
        <Col {...breadcrumbColProps}>
          <Breadcrumb {...breadcrumbProps}>
            <Breadcrumb.Item>
              <Link to="/">Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/blog/">Blog</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{title}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row {...rowProps}>
        <Col {...contentColProps}>
          <PageHeader {...pageHeaderProps}>
            <span className={style.date}>
              {publishedAt}
              {publishedAt !== updatedAt ? ` | Last Updated ${updatedAt}` : ``}
              <br />
              {`${post.wordCount.words} words (${post.timeToRead} min read)`}
            </span>
          </PageHeader>
        </Col>
        <Col {...contentColProps} style={{ marginTop: 24 }}>
          <div {...contentProps} />
        </Col>
      </Row>
      {wistiaVideoUrl !== null ? (
        <Row {...videoRowProps}>
          <Col {...videoColProps}>
            <Wistia {...wistiaVideoProps} />
          </Col>
        </Row>
      ) : (
        <></>
      )}
      <Row {...rowProps}>
        <Col {...contentColProps} style={{ padding: 16 }}>
          <BookingButton />
        </Col>
      </Row>
      <Divider />
      <Row {...rowProps}>
        <Col {...tagColProps}>
          <Typography.Title level={4}>Tags:</Typography.Title>
          {tags.map(tag => {
            return (
              <Tag className={style.tag} key={tag}>
                {tag}
              </Tag>
            )
          })}
          <SocialSharing pageUrl={href} />
        </Col>
      </Row>
      <BlogReel
        filterField="title"
        filterValue={title}
        numPosts={3}
        title="More Posts"
      />
    </Shell>
  )
}

export const pageQuery = graphql`
  query blogPostById($id: String!) {
    contentfulBlogPost(id: { eq: $id }) {
      title
      tags
      publishedAt(formatString: "MMM DD, YYYY")
      updatedAt(formatString: "MMM DD, YYYY")
      featuredImage {
        ...HeroFluid
      }
      content {
        childMarkdownRemark {
          excerpt(pruneLength: 150)
          html
          timeToRead
          wordCount {
            words
          }
        }
      }
      wistiaVideoUrl
    }
  }
`

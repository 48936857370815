import React from "react"
import PropTypes from "prop-types"
import style from "./DuotoneImage.module.scss"

const DuotoneImage = ({ alt, color1, color2, fluid, height, src }) => {
  const wrapperProps = {
    className: style.imgWrapper,
    style: {
      background: `${color1}`,
      height: height,
    },
  }

  const foregroundProps = {
    className: style.foreground,
    style: {
      background: `${color2}`,
    },
  }

  const imgProps = () =>
    fluid
      ? {
          alt: alt,
          srcSet: src.srcSet,
        }
      : {
          alt: alt,
          src: src,
        }

  return (
    <div {...wrapperProps}>
      <div {...foregroundProps}></div>
      <img {...imgProps()} alt={alt} />
    </div>
  )
}

DuotoneImage.propTypes = {
  alt: PropTypes.string.isRequired,
  color1: PropTypes.string.isRequired,
  color2: PropTypes.string.isRequired,
  fluid: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
}

DuotoneImage.defaultProps = {
  alt: null,
  color1: require("../../../config/theme")["info-color"],
  color2: require("../../../config/theme")["primary-color"],
  fluid: false,
  height: 350,
  image: null,
}

export default DuotoneImage

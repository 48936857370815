import React from "react"
import { btn } from "./BookButton.module.scss"

export default ({
  buttonProps = { style: {} },
  label = `BOOK ONLINE`,
  linkProps = { href: process.env.GATSBY_BOOKING_ENGINE, style: {} },
  triggerId = `book-online-button`,
}) => (
  <a id={triggerId} {...linkProps}>
    <button className={btn} {...buttonProps}>
      {label}
    </button>
  </a>
)
